import React from 'react';
import { firebase, provider } from "fire/firebase";
import { Input, Button, message, Row, Col } from 'antd';
import Password from 'antd/lib/input/Password';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    }
  }

  changeEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  changePassword = (e) => {
    this.setState({ password: e.target.value });
  }

  login = async () => {
    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(e => {
      this.setState({ password: '' });
      message.error("Invalid Credentials");
    });
  }

  render() {
    return (
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Input value={this.state.email} onChange={this.changeEmail} placeholder="Email" />
        </Col>
        <Col span={12}>
          <Password value={this.state.password} onChange={this.changePassword} placeholder="Password" />
        </Col>
        <Button size="large" onClick={this.login}>Login</Button>
      </Row >
    );
  }
}

export default Login;