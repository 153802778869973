import React from "react";
import axios from 'axios';
import { Row, Col, Input, Button } from 'antd';
import moment from 'moment';
import EmailModal from "EmailModal";
import { Link } from 'react-router-dom';

import "App.scss";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      filter: '',
      refreshing: false
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    this.setState({ refreshing: true });
    const res = await axios.get("https://us-central1-mergefly-admin.cloudfunctions.net/admin/users");
    const users = res.data;
    this.setState({ users, refreshing: false });
  }

  updateSearch = (e) => {
    this.setState({ filter: e.target.value });
  }

  listUsers = () => {
    return this.state.users.filter(user => user.email.includes(this.state.filter) || moment(user.lastUse).format("hh:mm A - MM/DD/YYYY").includes(this.state.filter))
      .sort((a, b) => {
        if (moment(a.lastUse).isAfter(moment(b.lastUse))) {
          return -1;
        }

        return 1;
      }).map(user => {
        return (
          <Row key={user.email} gutter={[16, 16]}>
            <Col span={5}><Link to={`user/${user.email}/${user.id}`}>{user.email}</Link></Col>
            <Col span={3}>{moment(user.lastUse).format("hh:mm A - MM/DD/YYYY")}</Col>
            <Col span={4}>{user.lastLoadTime || 'NA'}</Col>
            <Col span={4}>{user.loginHistory ? user.loginHistory.length : 0}</Col>
            <Col span={4}>{user.unsubscribe ? 'unsubscribed' : ''}</Col>
            <Col span={4}>{user.id}</Col>
          </Row>
        );
      });
  }

  generateIds = async () => {
    this.setState({ refreshing: true });
    for (var i = 0; i < this.state.users.length; i++) {
      const user = this.state.users[i];
      await axios.post("https://us-central1-mergefly-admin.cloudfunctions.net/admin/generateId", {
        email: user.email
      });
    }
    this.setState({ refreshing: false });
  }

  render() {
    const users = this.listUsers();

    return (
      <div className='App'>
        <div className="header">Mergefly Admin</div>
        <div>Total Users: {this.state.users.length}</div>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Input placeholder="Search" onChange={this.updateSearch} value={this.state.filter} allowClear />
          </Col>
          <Col span={2}>
            <Button loading={this.state.refreshing} type="primary" onClick={this.refresh}>Refresh</Button>
          </Col>
          <Col span={3}>
            <EmailModal users={this.state.users} />
          </Col>
          <Col span={2}>
            <Button loading={this.state.refreshing} type="default" onClick={this.generateIds}>Generate IDS</Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col className="colHeader" span={5}>Email</Col>
          <Col className="colHeader" span={3}>Last Use</Col>
          <Col className="colHeader" span={4}>Last Load Time</Col>
          <Col className="colHeader" span={4}>Historical Use</Col>
          <Col className="colHeader" span={4}>Unsubscribed</Col>
          <Col className="colHeader" span={4}>ID</Col>
        </Row>
        <div>
          {users}
        </div>
      </div>
    );
  }
}

export default App;
