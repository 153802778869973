import firebase from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyAwKU-9yPtcClAEh5LPcsVYd828lZ04py8",
  authDomain: "mergefly-admin.firebaseapp.com",
  databaseURL: "https://mergefly-admin.firebaseio.com",
  projectId: "mergefly-admin",
  storageBucket: "mergefly-admin.appspot.com",
  messagingSenderId: "169061616318",
  appId: "1:169061616318:web:a8498c14ebcdf37f14ecef"
};

firebase.initializeApp(config);

const provider = new firebase.auth.GoogleAuthProvider();

export { firebase, provider };
