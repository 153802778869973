import React from 'react';
import axios from 'axios';
import { Select, Button, Modal, Row, Col, Input } from 'antd';
import { WelcomeTemplate } from 'templates/welcome';
import { UrlNotification } from 'templates/urlNotification';
import { MergeflyMobileUpdate } from 'templates/mergeflyMobileUpdate';

class EmailModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      subject: 'Important URL Change',
      from: 'info@mergefly.com',
      loading: false,
      selectedUsers: [],
      template: 'urlNotification'
    };
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  closeModal = () => {
    this.setState({ visible: false });
  }

  updateTemplate = (value) => {
    this.setState({ template: value });
  }

  getTemplate = (email, userId) => {
    switch (this.state.template) {
      case 'welcome': {
        return WelcomeTemplate(email, userId);
      }
      case 'urlNotification': {
        return UrlNotification(email, userId);
      }
      case 'mergeflyMobileUpdate': {
        return MergeflyMobileUpdate(email, userId);
      }
      default: {
        return WelcomeTemplate(email, userId);
      }
    }
  }

  sendEmail = async () => {
    this.setState({ loading: true });

    for (let i = 0; i < this.state.selectedUsers.length; i++) {
      const user = this.state.selectedUsers[i];
      const fullUser = this.props.users.find(u => u.email === user);

      if (fullUser.unsubscribe) {
        continue;
      }

      const html = this.getTemplate(fullUser.email, fullUser.id);

      await axios.post("https://us-central1-mergefly-admin.cloudfunctions.net/admin/sendEmail", {
        subject: this.state.subject,
        to: fullUser.email,
        from: this.state.from,
        html: html
      });
    }

    this.setState({ loading: false });
  }

  getTemplates = () => {
    return (
      <Select style={{ width: '100%' }} showSearch value={this.state.template} onChange={this.updateTemplate}>
        <Select.Option value="welcome">Welcome</Select.Option>
        <Select.Option value="urlNotification">Url Notification</Select.Option>
        <Select.Option value="mergeflyMobileUpdate">Mergefly Mobile Update</Select.Option>
      </Select>
    );
  }

  updateSelectedUsers = (selectedUsers) => {
    this.setState({ selectedUsers });
  }

  getUsers = () => {
    const options = this.props.users.filter(user => !user.unsubscribe).map(user => {
      return <Select.Option key={user.email} value={user.email}>{user.email}</Select.Option>;
    });

    return (
      <Select placeholder="Select users for email" style={{ width: '100%' }} mode="multiple" showSearch value={this.state.selectedUsers} onChange={this.updateSelectedUsers}>
        {options}
      </Select>
    );
  }

  selectAllUsers = () => {
    const selectedUsers = this.props.users.filter(user => !user.unsubscribe).map(user => user.email);

    this.setState({ selectedUsers });
  }

  render() {
    return (
      <div>
        <Button type="dashed" onClick={this.showModal}>Send an Email</Button>
        <Modal
          title="Email Configurations"
          visible={this.state.visible}
          onCancel={this.closeModal}
          onOk={this.sendEmail}
          footer={[
            <Button key="back" onClick={this.closeModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.sendEmail}>
              Submit
            </Button>,
          ]}>
          <div>
            <Row>
              <Col span={12}>
                <label>Templates</label>
                {this.getTemplates()}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label>From:</label>
                <Input value={this.state.from} placeholder="from..." onChange={(e) => this.setState({ from: e.target.value })} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label>Subject:</label>
                <Input value={this.state.subject} placeholder="subject..." onChange={(e) => this.setState({ subject: e.target.value })} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label>Users</label>
                {this.getUsers()}
              </Col>
              <Col offset={1} span={4}>
                <label style={{ visibility: 'hidden' }}>...</label>
                <Button type="primary" onClick={this.selectAllUsers}>Select All</Button>
              </Col>
              <Col offset={1} span={4}>
                <label style={{ visibility: 'hidden' }}>...</label>
                <Button type="danger" onClick={() => this.setState({ selectedUsers: [] })}>Clear All</Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EmailModal;