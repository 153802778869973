import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import App from './App';
import User from './User';

class Routes extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact={true} path='/'>
            <App />
          </Route>
          <Route path='/user/:email/:userId' component={User} />
          <Route
            path='*'
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routes;
