export function MergeflyMobileUpdate(email, userId) {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
  <html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
        <!--[if !mso]><!-->
        <meta http-equiv="X-UA-Compatible" content="IE=Edge">
        <!--<![endif]-->
        <!--[if (gte mso 9)|(IE)]>
        <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->
        <!--[if (gte mso 9)|(IE)]>
    <style type="text/css">
      body {width: 800px;margin: 0 auto;}
      table {border-collapse: collapse;}
      table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
      img {-ms-interpolation-mode: bicubic;}
    </style>
  <![endif]-->
        <style type="text/css">
      body, p, div {
        font-family: arial,helvetica,sans-serif;
        font-size: 14px;
      }
      body {
        color: #000000;
      }
      body a {
        color: #337466;
        text-decoration: none;
      }
      p { margin: 0; padding: 0; }
      table.wrapper {
        width:100% !important;
        table-layout: fixed;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      img.max-width {
        max-width: 100% !important;
      }
      .column.of-2 {
        width: 50%;
      }
      .column.of-3 {
        width: 33.333%;
      }
      .column.of-4 {
        width: 25%;
      }
      @media screen and (max-width:480px) {
        .preheader .rightColumnContent,
        .footer .rightColumnContent {
          text-align: left !important;
        }
        .preheader .rightColumnContent div,
        .preheader .rightColumnContent span,
        .footer .rightColumnContent div,
        .footer .rightColumnContent span {
          text-align: left !important;
        }
        .preheader .rightColumnContent,
        .preheader .leftColumnContent {
          font-size: 80% !important;
          padding: 5px 0;
        }
        table.wrapper-mobile {
          width: 100% !important;
          table-layout: fixed;
        }
        img.max-width {
          height: auto !important;
          max-width: 100% !important;
        }
        a.bulletproof-button {
          display: block !important;
          width: auto !important;
          font-size: 80%;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        .columns {
          width: 100% !important;
        }
        .column {
          display: block !important;
          width: 100% !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
        .social-icon-column {
          display: inline-block !important;
        }
      }
    </style>
        <!--user entered Head Start--><!--End Head user entered-->
      </head>
      <body>
        <center class="wrapper" data-link-color="#337466" data-body-style="font-size:14px; font-family:arial,helvetica,sans-serif; color:#000000; background-color:#ffffff;">
          <div class="webkit">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#ffffff">
              <tr>
                <td valign="top" bgcolor="#ffffff" width="100%">
                  <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                    <tr>
                      <td width="100%">
                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                          <tr>
                            <td>
                              <!--[if mso]>
      <center>
      <table><tr><td width="800">
    <![endif]-->
                                      <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width:100%; max-width:800px;" align="center">
                                        <tr>
                                          <td role="modules-container" style="padding:0px 0px 0px 0px; color:#000000; text-align:left;" bgcolor="#ffffff" width="100%" align="left"><table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%" style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
      <tr>
        <td role="module-content">
          <p></p>
        </td>
      </tr>
    </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="623d612b-da87-4a41-bdea-b113bf3a88e1">
      <tbody>
        <tr>
          <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
            <a href="mergefly.com"><img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:40% !important; width:40%; height:auto !important;" width="320" alt="" data-proportionally-constrained="true" data-responsive="true" src="http://cdn.mcauto-images-production.sendgrid.net/19f3ed42ffaf20d7/5e4ad4f4-b8f7-4c7d-a9f4-c170832d4cf7/862x675.png"></a>
          </td>
        </tr>
      </tbody>
    </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="4f4f6f0b-5336-4aa2-a7ed-8b17e283c675" data-mc-module-version="2019-10-22">
      <tbody>
        <tr>
          <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="font-size: 24px"><strong>Mergefly</strong></span></div><div></div></div></td>
        </tr>
      </tbody>
    </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="afd1cf91-4807-4a3e-a2b8-839eac4509bd" data-mc-module-version="2019-10-22">
      <tbody>
        <tr>
          <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: inherit">The Mergefly team is proud to announce the release of Mergefly 5.0. This is a big and exciting update and it will be available starting Monday, May 18th. So, without further ado, let's get right to the features that you can look forward to!&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit"><br></div>
  <div style="font-family: inherit; text-align: inherit">The main updates we made are:&nbsp;</div>
  <ol>
    <li style="text-align: inherit; font-family: inherit">The site navigation has been updated&nbsp;</li>
    <li style="text-align: inherit; font-family: inherit">Multiple tabs have been replaced by a Recent Activity Navbar&nbsp;</li>
    <li style="text-align: inherit; font-family: inherit">The home page got some TLC&nbsp;</li>
    <li style="text-align: inherit; font-family: inherit">Mergefly is now mobile ready&nbsp;</li>
  </ol>
  <div style="font-family: inherit; text-align: inherit"><strong>Site Navigation</strong></div>
  <div style="font-family: inherit; text-align: inherit">The first major update actually came from a seemingly small problem: the browser back button would have users leave the site. If, for example, you were looking at a pull request and you tried to go back to the repository with the browser back button, you would instead find yourself on the site you were on before Mergefly! This with the combination of multiple tabs created a lot of confusion with our navigation system. This was fixed by cleaning up the internal router so now the back button will behave exactly as you expect.&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit"><br></div>
  <div style="font-family: inherit; text-align: inherit">** It is important to note that after this update, previous Mergefly links will not work anymore. We are sorry for the inconvenience this may cause, but we believe that the transition is worth it.&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit"><br></div>
  <div style="font-family: inherit; text-align: inherit"><strong>Goodbye Multiple Tabs</strong></div>
  <div style="font-family: inherit; text-align: inherit">The main downside to the router update is that we could not keep multiple tabs. We debated this for a long time, but ultimately felt that the original purpose of multiple tabs was to let users switch between repositories and pull requests very quickly. However, the concept of a browser within a browser was again too confusing and, while a cool concept, didn't feel like it was really getting the job done.&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit"><br></div>
  <div style="font-family: inherit; text-align: inherit"><strong>Recent Activity Navbar</strong></div>
  <div style="font-family: inherit; text-align: inherit">To replace multiple tabs we created the Recent Activity Navbar. At the top left of every page, you can click on the Mergefly logo and a sidebar will pop out with a list of links to all of the repositories and pull requests you have recently visited. We also put sections for your repositories and open pull requests (just like on the home page) to make navigation as fast and simple as possible. Best of all, your recent activity syncs across devices which means you can easily get back to your last location regardless of which browser tab or computer you use.&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit"><br></div>
  <div style="font-family: inherit; text-align: inherit"><strong>Home Page</strong></div>
  <div style="font-family: inherit; text-align: inherit">Speaking of the home page, the third area we focused on was a general home page cleanup. We received lots of feedback telling us that the home page felt uninspired and clunky. So, we updated it. The page now has a cleaner layout and more information and should be much more enjoyable and intuitive to use.&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit"><br></div>
  <div style="font-family: inherit; text-align: inherit"><strong>Mergefly Mobile</strong></div>
  <div style="font-family: inherit; text-align: inherit">Finally, we are excited to announce that Mergefly is mobile ready! Now you can browse code and review pull requests on the go on any size device with all of Mergefly's features readily available. Plus, with your recent activities now in sync across devices, switching between your laptop and phone has never been easier.&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit"><br></div>
  <div style="font-family: inherit; text-align: inherit">We hope you enjoy all of these updates as much as we do and we can't wait to hear your thoughts on them once they go live on May 18th. As always, thank you so much for being a Mergefly user.&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit"><br></div>
  <div style="font-family: inherit; text-align: inherit">We look forward to hearing from you!&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit"><br></div>
  <div style="font-family: inherit; text-align: inherit">Happy Merging,&nbsp;</div>
  <div style="font-family: inherit; text-align: inherit">The Mergefly Team</div><div></div></div></td>
        </tr>
      </tbody>
      </table><div data-role="module-unsubscribe" class="module" role="module" data-type="unsubscribe" style="color:#444444; font-size:12px; line-height:20px; padding:16px 16px 16px 16px; text-align:Center;" data-muid="4e838cf3-9892-4a6d-94d6-170e474d21e5"><div class="Unsubscribe--addressLine"></div><p style="font-size:12px; line-height:20px;"><a class="Unsubscribe--unsubscribeLink" href="https://us-central1-mergefly-2.cloudfunctions.net/api/unsubscribe?email=${email}&userId=${userId}" target="_blank" style="">Unsubscribe</a></p></div></td>
                                        </tr>
                                      </table>
                                      <!--[if mso]>
                                    </td>
                                  </tr>
                                </table>
                              </center>
                              <![endif]-->
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </center>
      </body>
    </html>
  `;
}
