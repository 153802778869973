import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";
import Login from "./Login";
import { firebase } from "fire/firebase";
import * as serviceWorker from "./serviceWorker";
import Routes from "Routes";

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    ReactDOM.render(<Routes />, document.getElementById("root"));
  } else {
    ReactDOM.render(<Login />, document.getElementById("root"));
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
