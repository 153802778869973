import React from "react";
import axios from 'axios';
import { Row, Col, Spin, Button } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshing: false,
      activity: {}
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh = async () => {
    this.setState({ refreshing: true });
    const res = await axios.get(`https://us-central1-mergefly-admin.cloudfunctions.net/admin/userActivity?userId=${this.props.match.params.userId}`);
    const activity = res.data;

    this.setState({ activity, refreshing: false });
  }

  getActivity = () => {
    if (this.state.refreshing) {
      return <Spin />;
    }

    if (!Object.keys(this.state.activity).length) {
      return <h2>No Activity Tracked For This User</h2>;
    }

    const activityMapping = Object.keys(this.state.activity).map(action => {
      return {
        action,
        time: this.state.activity[action]
      }
    })

    return activityMapping.sort((a, b) => {
      if (moment(a.time).isAfter(moment(b.time))) {
        return -1;
      }

      return 1;
    }).map((activity, index) => {
      return (
        <Row key={index} gutter={[16, 16]}>
          <Col span={6}>
            {activity.action}
          </Col>
          <Col span={6}>
            {activity.time}
          </Col>
        </Row>
      )
    })
  }

  render() {
    const activity = this.getActivity();

    return (
      <div className='App'>
        <Link to="/">Back</Link>
        <div>
          <Button onClick={this.refresh} loading={this.state.refreshing} type="primary">Refresh</Button>
        </div>
        <h1>{this.props.match.params.email}</h1>
        {activity}
      </div>
    );
  }
}

export default User;
